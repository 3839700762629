.bottomButton {
  width: 100%;
}

.serialNumberForm {
  height: 100%;
  width: 100%;
  margin-left: 24px;
  margin-top: 8px;
}

.barcodeInput {
  margin-top: 8px;
}
