div.ant-card-head-title {
  padding: 0px !important;
}

.batchSelected {
  border-color: var(--tracis-purple) !important;
}

.checkBox {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 9;
  padding: 8px;
}

.pullLeft {
  position: absolute;
  bottom: 3px;
  left: 0px;
  color: gray;
}

.pullRight {
  position: absolute;
  bottom: 0px;
  right: 64px;
  color: gray;
}

.selected {
  background-color: #35393c;
}

.selected:hover {
  background-color: #35393c !important;
}
