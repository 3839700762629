.fadeIn {
  animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

:root {
  --red: #cf1322; /* antd red-7 */
  --red-hover: #f5222d; /* antd red-6 */
  --green: #389e0d; /* antd green-7 */
  --green-hover: #52c41a; /* antd green-6 */
  --tracis-purple: #a288ff;
  --tracis-purple-dark: #30284c;
  --tracis-purple-hover: #c4b0ff;
  --blue: #096dd9; /* antd blue-7 */
  --yellow: #d4b106; /* antd yellow-7 */
  --light-gray: #bfbfbf; /* antd gray-6 */
  --mid-gray: #2a2e31;
  --border-gray: #34383b;
}

.red {
  color: var(--red);
}

.redBackground {
  border-color: var(--light-gray);
  background-color: transparent;
}

.redBackground:hover {
  background-color: var(--red-hover);
  border-color: var(--red-hover);
}

.green {
  border-color: inherit;
  color: var(--green);
}

.greenBackground {
  border-color: var(--light-gray);
  background-color: transparent;
}

.greenBackground:hover {
  background-color: var(--green-hover);
  border-color: var(--green-hover);
}

.purple {
  color: var(--tracis-purple);
}

.blue {
  color: var(--blue);
}

.yellow {
  color: var(--yellow);
}

.centeredVH {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;
}

.itemTitle {
  width: 100%;
}

/* Inventory lens card styling. */
.card {
  width: calc(100% - 16px);
  min-height: 216px;
  margin: 0px 16px 16px 16px;
  border-radius: 6px;
  border: 1px solid #34383b;
  cursor: pointer;
}

.addLensTrigger.listItem {
  width: 100%;
  height: 64px;
}

.card svg[data-icon="fullscreen"],
.scanCard svg[data-icon="fullscreen"] {
  color: #2a2e31;
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.lensCard {
  padding-top: 8px;
}

.lensCard.card:hover svg[data-icon="fullscreen"],
.scanCard:hover svg[data-icon="fullscreen"],
.scanCard.tourHover svg[data-icon="fullscreen"] {
  color: var(--tracis-purple);
}

.scanCard:hover svg[data-icon="fullscreen"],
.scanCard.tourHover svg[data-icon="fullscreen"] {
  position: absolute;
  bottom: 2px;
  left: -1px;
}

.lensCard.card:hover,
.scanCard:hover:not(.disabled),
.scanCard.tourHover {
  box-shadow: 0px 0px 8px 8px #212427;
  border-color: #484b4e;
  background-color: #2f3336;
}

.scanCard.disabled:hover {
  cursor: not-allowed;
}

.listItem {
  padding: 0px 8px;
  height: 74px;
}

.listItem:hover {
  background-color: #2f3336;
  cursor: pointer;
}

.ant-select.ant-select-single.ant-select-show-arrow .ant-select-selector,
.ant-input {
  border-radius: 6px;
}

.ant-radio-button-wrapper {
  background-color: transparent;
  color: var(--tracis-purple);
}

.ant-radio-button-wrapper,
.ant-radio-button-wrapper:first-child {
  border-radius: 6px 0 0 6px;
  border-color: var(--tracis-purple);
}

.ant-radio-button-wrapper:hover {
  color: var(--tracis-purple-hover);
}

.ant-radio-button {
  border-color: var(--tracis-purple);
}

.ant-radio-button-checked:hover {
  border-color: var(--tracis-purple-hover);
  background-color: var(--tracis-purple-hover);
  color: white !important;
}

.ant-radio-button-wrapper:last-child,
.ant-input-group-addon {
  border-radius: 0 6px 6px 0;
  border-color: var(--tracis-purple);
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: white;
}

.ant-select.ant-select-auto-complete.ant-select-single.ant-select-show-search
  .ant-select-selector {
  border-radius: 6px;
}
