.salesCta {
  width: 200px;
  height: 50px;
  background-color: #a288ff;
  position: fixed;
  bottom: 70px;
  right: 0;
  margin: 20px;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 0px 8px 18px rgba(0, 0, 0, 0.04);
  color: #25292c;
}
