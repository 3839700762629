.header {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}

.headerContainer {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.logo {
  max-height: 100px;
  max-width: 50%;
}

.compareFilter {
  margin-right: 20px;
}

.printableCardContainer {
  max-width: 1000px;
  position: relative;
}

.page {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
