.ant-modal-body::-webkit-scrollbar {
  width: 11px;
}

.ant-modal-body::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
}

.ant-modal-body::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 6px;
  border: 3px solid var(--scrollbar-background);
}
