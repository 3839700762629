.signInContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.module {
  text-align: center;
  width: 440px;
  border: 1px solid var(--border-gray);
  border-radius: 6px;
  background: var(--mid-gray);
  margin: 12px auto;
  padding: 12px 24px;
  justify-content: space-between;
}
