.undoRedo {
  position: relative;
  outline: 1px solid #34383b;
}

.tools {
  position: relative;
  outline: 1px solid #34383b;
}

.toolButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.share {
}

/* TALL */
@media (min-width: 320px) {
  .controls {
    display: flex;
    position: fixed;
    width: 96vw;
    flex-wrap: wrap;
    bottom: 4vh;
    background-color: #2a2e31;
  }

  .undoRedo {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: none;
    width: 20%;
  }

  .tools {
    padding: 6px;
    width: 60%;
  }

  .toolsContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .toolButton {
    margin: 4px;
  }

  .colors {
    height: 80px;
    width: 50%;
  }

  .save {
    display: none;
  }

  .share {
    outline: 1px solid #34383b;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .optionsButton {
    display: flex;
    cursor: pointer;
    outline: 1px solid #34383b;
    justify-content: center;
    align-items: center;
    width: 20%;
  }

  .scratchSize {
    width: 100%;
  }

  .hidden {
    display: none;
  }
}

/* WIDE */
@media (min-width: 961px) {
  .controls {
    display: block;
    outline: 1px solid #34383b;
    position: relative;
    height: 86vh;
    width: 200px;
    bottom: 0;
  }

  .undoRedo {
    width: 100%;
    height: 16%;
  }

  .tools {
    display: block;
    width: 100%;
    height: 30%;
  }

  .toolsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80%;
  }

  .toolButton {
    margin: 10px;
    width: 60px;
  }

  .colors {
    display: block;
    position: relative;
    width: 100%;
    outline: 1px solid #34383b;
    padding: 0 6px;
    height: 14%;
  }

  .save {
    display: flex;
    position: absolute;
    width: 100%;
    outline: 1px solid #34383b;
    height: 20%;
    bottom: 0;
    justify-content: center;
    align-items: center;
  }

  .share {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .optionsButton {
    display: none;
  }
}

.undoButton {
  background: none;
  outline: none;
  border: none;
  margin: 2%;
}

.colorContainer {
  display: flex;
}

.colorFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
}

.colorSelector {
  color: black;
  height: 22px;
  width: 22px;
  margin: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolButtons {
  display: flex;
  justify-content: center;
}
