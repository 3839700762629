.printableLensWrapper {
  padding: 20px;
}

.lens {
  position: relative;
  display: flex;
  height: 26.3%; /* This is very sensitive, changing will upset page breaks. */
}

.lensHeader {
  width: 100%;
  font-size: 2em;
}

.detailsContainer {
  width: 20%;
}

.imageContainer {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.focalLength {
  font-size: 2em;
}

.serialNumber {
  font-size: 1.2em;
}

.rearContainer {
  position: relative;
  height: 100%;
  width: 40%;
}

.surfaceImage {
  position: absolute;
  height: 100%;
  transform: scale(0.97);
}

.fullWidth {
  width: 100%;
}

.noContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rear {
  transform: scale(0.9);
}

.lensOutline {
  position: relative;
  width: 100%;
  max-height: 100%;
}

.surfaceLabel {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.flip {
  transform: scaleX(-1);
}

.showOnPrintout {
  display: none;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .showOnPrintout {
    display: block;
  }
  .lens {
    color: black;
  }
  .lensHeader {
    color: black;
  }
  .colorLogo {
    max-height: 60px;
  }
  .outlineHidden {
    display: none;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
  .pageHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .certifiedByContainer {
    display: flex;
    flex-direction: column;
    width: 200px;
  }

  .certifiedBy {
    color: black;
    display: inline-block;
    text-align: right;
    height: 10px;
  }

  .tracisLogo {
    width: 200px;
    transform: translateY(5px);
  }

  .detailsContainer {
    width: 36%;
  }

  .surfaceImage {
    transform: scale(0.94);
  }
}

@page {
  size: auto;
  margin: 20px;
}
