.signInContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signIn {
  background-color: #2a2e31;
  border: 1px solid #34383b;
  width: 440px;
  padding: 10px 30px;
  text-align: center;
}

.emailAndPassword {
  margin-top: 20px;
}

.margin {
  margin: 4px 0;
}

.fieldDecorator {
  font-size: smaller;
  font-style: italic;
  color: var(--red);
}

.module {
  text-align: center;
  width: 440px;
  border: 1px solid var(--border-gray);
  border-radius: 6px;
  background: var(--mid-gray);
  margin: 12px auto;
  padding: 12px 24px;
  justify-content: space-between;
}
