.shareIcon {
  font-size: x-large;
  color: #a288ff;
  margin-right: 8px;
}

.modalTitle {
  font-size: large;
  vertical-align: middle;
}

.modalDescription {
  font-size: 16px;
}

.modalDate {
  background-color: #25292c;
}

.modalWarning {
  font-size: small;
  font-style: italic;
  margin-left: 2em;
}

.shareActionButton {
  padding: none !important;
}

.endJustify {
  text-align: end;
}

.shareRow {
  margin-bottom: 8px;
  vertical-align: middle;
  height: 100%;
}

.mainShareRow {
  margin-top: 8px;
}
